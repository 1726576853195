<template>
    <v-dialog :persistent="deletingBundle" v-model="deleteBundleDialog" max-width="700">
        <template v-slot:activator="{ on: dialog }">
            <v-tooltip right>
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn v-on="{ ...tooltip, ...dialog }" icon text color="secondary"><v-icon>delete</v-icon></v-btn>
                </template>
                <span>Delete</span>
            </v-tooltip>
        </template>
        <v-card flat>
            <v-card-title>
                <div class="d-flex align-center w-100">
                    <v-icon>delete</v-icon>
                    <span class="text-uppercase secondary--text ml-1">delete {{ bundleData.bundle_type_name }}</span>
                    <v-spacer></v-spacer>
                </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <div class="mt-3">
                    <v-alert type="info" prominent text>
                        Deleting a
                        <span class="text-lowercase">{{ bundleData.bundle_type_name }}</span>
                        does not remove the objects that have been distributed.
                    </v-alert>
                    <div class="mt-3">
                        Are you sure you want to delete
                        <span class="font-weight-bold">{{ bundleData.long_id }}</span>
                        ?
                    </div>
                </div>
            </v-card-text>
            <v-card-actions>
                <div class="d-flex align-center justify-end w-100">
                    <v-btn :disabled="deletingBundle" color="secondary" text @click="deleteBundleDialog = false">close</v-btn>
                    <v-btn :loading="deletingBundle" @click="deleteBundle" text color="error">Delete</v-btn>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'BundleDeleteDialog',
    props: {
        bundleData: Object
    },
    data() {
        return {
            deleteBundleDialog: false,
            deletingBundle: false
        }
    },
    methods: {
        deleteBundle() {
            this.deletingBundle = true
            this.$axios
                .delete(`/bundles/${this.bundleData.bid}`)
                .then(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: `Successfully deleted ${this.bundleData.long_id}`,
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                    this.$store.dispatch('spaceStore/fetchSpaceBundles', this.$route.params.sid)
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: `Failed to delete ${this.bundleData.long_id}`,
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.deletingBundle = false
                    this.deleteBundleDialog = false
                })
        }
    }
}
</script>
